<template>
    <div class="container">

        <van-nav-bar title="龙卡营销工具" left-arrow @click-left="goBack()">
        </van-nav-bar>
        <div>
            <van-cell-group title="12月7号始">
                <van-cell title="1万龙卡" is-link to="/question/detail?id=9542eabe-f17f-4a19-8052-dff594e60d09" />
            </van-cell-group>
            <van-cell-group title="12月12号始，方案一，用户未实充值">
                <van-cell title="3万龙卡" is-link to="/questiontype/detail?id=ac572f90-2856-415d-af55-5fffefa2997d" />
                <van-cell title="10万龙卡" is-link to="/questiontype/detail?id=4a5a4393-4908-4ff8-ad6f-da042fd510ef" />
                <van-cell title="30万龙卡" is-link to="/questiontype/detail?id=d51bc81c-1e5b-4222-bf4a-fa55aed0fd52" />
            </van-cell-group>
            <van-cell-group title="12月12号始，方案二，用户已打款到账">
                <van-cell title="生成龙卡" is-link to="/question/cardmeber"  label="龙卡为电子实名制卡，员工不可识别绑定，不要发错客户!" label-class="celllable" />
                
            </van-cell-group>

        </div>
    </div>
</template>
  
<script>


export default {
    name: "znarticledetail",
    data() {
        return {
        };
    },
    components: {
        
    },
    updated() {
    },
    created() {
    },
    watch: {
    },
    mounted() { },

    methods: {
        goBack() {
            if (this.$route.query.back) {
                this.$router.back();
            }
            else {
                this.$router.replace({ path: '/' })
            }
        },


    },
};
</script>
  
<style lang="less" scoped>
.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}
               
.celllable{
    color: #d10707;
}
</style>